@import 'variables.css';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
  font-size: var(--one-rem-size);
  background: var(--background-color--primary);
  color: var(--font-color--primary);
  scroll-behavior: smooth;
}

body > main {
  height: 100dvh;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

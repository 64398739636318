:root {
	--one-rem-size: 10px;
	--font-family: 'Roboto Light';
	
	/* Sizes */
	/*--size-xs: NOTE: less than 576px*/
	--size-sm: 57.6rem;
	--size-m: 76.8rem;
	--size-lg: 99.2rem;
	--size-xl: 120rem;
	--size-xxl: 160rem;
	
	/* Font */
	/*--font-family: var(--font-family, 'Roboto');*/
	
	--font-size-h1: 3.4rem;
	--font-size-h2: 3.0rem;
	--font-size-h3: 2.6rem;
	--font-size-h4: 2.2rem;
	--font-size-h5: 1.8rem;
	--font-size-p: 1.6rem;
	--font-size-li: 1.6rem;
	--font-size-span: 1.6rem;
	
	/* Colors */
	--color-black: #000000;
	--color-white: #ffffff;
	--color-gray-20: #d3d3d3;
	--color-accent: #6531b4;
	--color-accent-2: #ff009d;
	
	--background-color--primary: var(--color-white);
	--font-color--primary: var(--color-black);
	
	/* Components */
	--page-header-height: 8.4rem;
	--page-content-height: calc(100dvh - var(--page-header-height) - var(--page-footer-height));
	--page-content-max-width: 124rem;
	--page-content-padding: 3.2rem;
	
	--banner-button-height: 5rem;
	--banner-max-height--collapsed: calc(var(--banner-button-height) * 2.5);
	--banner-max-height--expanded: 75dvh;
	
	--page-footer-min-extra-height: 4rem;
	--page-footer-height: calc(var(--banner-max-height--collapsed) + var(--page-footer-min-extra-height));
}

/*@media (prefers-color-scheme: dark) {*/
/*	:root {*/
/*		--background-color--primary: var(--color-black);*/
/*		--font-color--primary: var(--color-white);*/
/*	}*/
/*}*/
